import { logger } from '@utils/logger'
import { SecureSettings, ZendeskApiClient, ZendeskApiConsumer } from './zendesk-api'

// Enter all new flags here with a code-friendly name
export const flags = [
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=15069
  { __appname: 'bulk-mailer', name: 'hideSignatureInPreview', flag: 'frontend.bulkmailer.hidepreviewsignature' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=39813
  {
    __appname: 'bulk-mailer',
    name: 'showCampaignImportExport',
    flag: 'frontend.bulkmailer.show-campaign-import-export',
  },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=46817
  { __appname: 'bulk-mailer', name: 'bulkMailerFlagTest', flag: 'frontend:bulkmailer:flagtest' },
  { __appname: 'bulk-mailer', name: 'useNewSubscriberCounter', flag: 'bulkapi.flags.usenewsubscribercounter' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=50286
  { __appname: 'response-library', name: 'responseLibraryFlagTest', flag: 'frontend:responselibrary:flagtest' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=29345
  { __appname: 'all', name: 'useUnifiedEditor', flag: 'frontend:flags:unifiededitor' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=44325
  { __appname: 'all', name: 'useStrikethrough', flag: 'frontend:flags:strikethrough' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=31697
  { __appname: 'ask-abe', name: 'showHolidayMessage', flag: 'frontend:ask-abe:show-holiday-message' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=31698
  { __appname: 'ask-abe', name: 'showAfterHoursMessage', flag: 'frontend:ask-abe:show-after-hours-message' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=41314
  { __appname: 'ticket-logger', name: 'useModifiedHTMLMacroApply', flag: 'flags:usemodifiedhtmlmacroapply' },
  // https://app.flagsmith.com/project/2433/environment/Poi67urGHJMPXEZKd99BED/features?feature=61387
  { __appname: 'bulk-mailer', name: 'disableAutosave', flag: 'frontend:bulk-mailer:disable-autosave' },
  { __appname: 'bulk-mailer', name: 'bulkMailerOutageNotice', flag: 'frontend:bulkmailer:outage-notice' },
  { __appname: 'bulk-mailer', name: 'bulkMailerOutageOverride', flag: 'frontend:bulkmailer:outage-override' },
  { __appname: 'all', name: 'useReducedMacrosPageSize', flag: 'frontend:flags:reduced-macros-page-size' },
  { __appname: 'bulk-mailer', name: 'allowBulkMailerAdvancedListQuery', flag: 'frontend:bulkmailer:advanced-list' },
] as const

export const flaggedApps = [...new Set(flags.map(f => f.__appname))]
export type FlaggedAppName = typeof flaggedApps[number]
export const flagNames = flags.map(f => f.name)
export type FlagName = typeof flagNames[number]

export type FlagContext = Record<FlagName, boolean>

export type GetFlagsResponse = {
  [flag: string]: boolean
}

export const getFlagOverrides = (): Partial<FlagContext> => {
  if (typeof window === 'undefined') return {}

  try {
    const flags = window.localStorage.getItem('flags')

    if (!flags) {
      return {}
    }

    const overrides = JSON.parse(flags) as Partial<FlagContext>
    // eslint-disable-next-line no-console
    Object.keys(overrides).length && console.log('⛳️ Using custom feature flag overrides', overrides)
    return overrides
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Unable to parse flag overrides from localStorage')
    return {}
  }
}

export const setFlagOverrides = (overrides: Partial<FlagContext>) => {
  if (typeof window === 'undefined') return
  window.localStorage.setItem('flags', JSON.stringify(overrides))
}

export class FlagsClient extends ZendeskApiConsumer {
  private baseUrl = ''
  constructor(args: { zendeskClient: ZendeskApiClient }) {
    super(args)
    this.baseUrl = process.env.FLAGS_ENDPOINT || (args.zendeskClient.settings['flags_url'] as string)
  }

  getFlags = async ({
    flags,
    subdomain,
    cohort,
  }: {
    flags: any[]
    subdomain: string
    cohort?: string
  }): Promise<GetFlagsResponse> => {
    return this.request(`${this.baseUrl}/v1/flags?${flags.map(flag => `flags=${flag}`).join('&')}`, subdomain, cohort)
      .then(flags => {
        logger.info(`Fetched flags: ${JSON.stringify(flags.value)}`)
        return flags.value
      })
      .catch(error => {
        logger.error('Error fetching flags', { error })

        return null
      })
  }

  private request = (url: string, subdomain: string, cohort?: string): Promise<any> => {
    return this.zendeskClient.request({
      url,
      contentType: 'application/json',
      headers: {
        'Content-Type': 'application/json',
        'x-flag-token': SecureSettings.FlagsToken,
        'x-office': subdomain,
        'x-cohort': cohort,
        'x-origin': 'zendesk',
      },
      secure: true,
    })
  }
}
